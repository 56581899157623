<template>
	<form @submit.prevent="submit">
		<div class="mb-3">
			<input v-model="data.code"
			       @focusout="validateCode"
			       id="code"
			       :class="'input-field' + (errors.code ? ' input-field-error' : '')"
			       type="text"
			       placeholder="Enter your code"
			       required>
			<div v-if="errors.code" class="mt-2">
				<small class="form-text mt-2 font-weight-bold text-danger text-left">
					<img src="../assets/error.png" height="15" width="15" alt="Error">
					{{ errors.code }}
				</small>
			</div>
		</div>

		<div class="mb-3">
			<input v-model="data.password"
			       @focusout="validatePassword"
			       id="password"
			       :class="'input-field' + (errors.password ? ' input-field-error' : '')"
			       type="password"
			       placeholder="Enter your password"
			       required>
			<div v-if="errors.password" class="mt-2">
				<small class="form-text mt-2 font-weight-bold text-danger text-left">
					<img src="../assets/error.png" height="15" width="15" alt="Error">
					{{ errors.password }}
				</small>
			</div>
		</div>

		<div class="mb-3">
			<input v-model="data.password_confirmation"
			       @focusout="validatePasswordConfirmation"
			       id="password_confirmation"
			       :class="'input-field' + (errors.password_confirmation ? ' input-field-error' : '')"
			       type="password"
			       placeholder="Enter your password confirmation"
			       required>
			<div v-if="errors.password_confirmation" class="mt-2">
				<small class="form-text mt-2 font-weight-bold text-danger text-left">
					<img src="../assets/error.png" height="15" width="15" alt="Error">
					{{ errors.password_confirmation }}
				</small>
			</div>
		</div>

		<div v-if="error" class="mt-2 mb-3">
			<small class="form-text mt-2 font-weight-bold text-danger text-left">
				<img src="../assets/error.png" height="15" width="15" alt="Error">
				{{ error }}
			</small>
		</div>

		<button v-bind:disabled="!Object.values(errors).every(item => item === null || item === '') || errors.length === 0"
		        type="submit"
		        class="btn btn-primary btn-block">
			<span v-if="loading" class="spinner-border spinner-border-sm"></span>
			{{ submitButtonText }}
		</button>

		<button @click="resend" type="button" class="btn btn-secondary btn-block">
			<span v-if="resendLoading" class="spinner-border spinner-border-sm"></span>
			{{ resendButtonText }}
		</button>
	</form>
</template>

<script>
import auth from "../auth";
import statics from "../static";

export default {
	name: "ResetPasswordForm",
	props: {
		forgotEmail: {
			type: String,
			required: true
		}
	},
	computed: {
		submitButtonText() {
			return this.loading ?
				'Loading...' :
				'Reset password'
		},
		resendButtonText() {
			return this.resendLoading ?
				'Loading...' :
				'Resend'
		}
	},
	data() {
		return {
			error: null,
			errors: {},
			loading: false,
			resendLoading: false,
			data: {
				email: this.forgotEmail,
				code: null,
				password: null,
				password_confirmation: null
			}
		}
	},
	methods: {
		validateCode() {
			if (!this.data.code || this.data.code.length < 6) {
				this.errors.code = 'Code should be at least 6 characters long'
				return
			}

			this.errors.code = null
		},
		validatePassword() {
			if (!this.data.password || this.data.password.length < 6) {
				this.errors.password = 'Password should be at least 6 characters long'
				return
			}

			this.errors.password = null
		},
		validatePasswordConfirmation() {
			if (this.data.password_confirmation !== this.data.password) {
				this.errors.password_confirmation = 'Password and its confirmation do not match'
				return
			}

			this.errors.password_confirmation = null
		},
		resend() {
			this.error = null
			this.resendLoading = true

			auth.sendResetCode({
				email: this.data.email
			})
				.then(data => {
					this.resendLoading = false
				})
				.catch(error => {
					this.loading = false
					let errorData = error.response.data.errors
					this.error = errorData['email'][0] ?? error.response.data.message
				})
		},
		submit() {
			this.errorMessage = null
			this.loading = true

			auth.resetPassword(this.data)
				.then(data => {
					this.loading = false
					this.$router.push({
						name: "Login",
						params: {
							email: this.data.email
						}
					})
				})
				.catch(error => {
					this.loading = false
					if (error.response.status === 422) {
						let errorData = error.response.data.errors
						for (const key of Object.keys(errorData)) {
							this.errors[key] = errorData[key][0]
						}
					} else if (error.response.status === 500) {
						this.error = statics.server_error
					} else {
						this.error = error.response.data.message
					}
				})
		}
	}
}
</script>

<style scoped>

</style>
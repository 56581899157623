<template>
	<div class="container auth-form p-4">
		<div class="row">
			<div class="col-5 text-center">
				<h1 class="header-text-font text-left-color">Reset Password</h1>
				<p class="paragraph-text-font text-left-color">If you can't remember your password, you can reset it here. Just enter your e-mail address and we'll send you an email with link which will allow you to reset your password.</p>
			</div>
			<div class="col-1"></div>
			<div class="col-6 text-center">
				<div class="d-grid mb-3 text-center">
					<h1 class="header-text-font text-right-color">Reset Password</h1>
					<p class="paragraph-text-font text-right-color">Enter your new password, its confirmation and the code you received in your emails address.</p>
				</div>
				<reset-password-form :forgot-email="$route.params.email" />
			</div>
		</div>
	</div>
</template>

<script>
import ResetPasswordForm from "../components/ResetPasswordForm";
export default {
	name: "ResetPassword",
	components: {ResetPasswordForm}
}
</script>

<style>

#app {
	background: linear-gradient(
		to right,
		#141850 0%,
		#303179 45%,
		#fae5df 0%,
		#fae5df 0%
	) 0 0 / cover;
}

</style>
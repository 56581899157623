import axios from 'axios'

export default {
    login: (data) => new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_BASE_URL  + '/auth/login', data)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    }),
    sso: (uuid) => new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_BASE_URL  + `/auth/sso/${uuid}`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    }),
    signIn: (data) => new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_BASE_URL  + '/auth/sign_in', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
    }),
    register: (data) => new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_BASE_URL  + '/auth/register', data)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    }),
    verify: (data) => new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_BASE_URL  + '/auth/verify', data)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    }),
    resend: (data) => new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_BASE_URL  + '/auth/resend', data)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    }),
    sendResetCode: (data) => new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_BASE_URL  + '/auth/send_reset_code', data)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    }),
    resetPassword: (data) => new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_BASE_URL  + '/auth/reset_password', data)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    }),
    pass2FA: (data) => new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_BASE_URL  + '/auth/pass_2fa', data)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    }),
    recover2FA: (data) => new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_BASE_URL  + '/auth/recover_2fa', data)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}